var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Form", attrs: { id: "Form" } }, [
    _vm.formObj
      ? _c(
          "div",
          { staticStyle: { padding: "15px" } },
          [
            _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
              _c(
                "p",
                { staticStyle: { "font-size": "24px", "font-weight": "500" } },
                [_vm._v(_vm._s(_vm.$t(_vm.formObj.title)))]
              )
            ]),
            _c(
              "el-form",
              {
                ref: "dynamicValidateForm",
                staticClass: "demo-dynamic",
                class: _vm.formObj.formproperties.classname,
                style: "width: " + _vm.formObj.formproperties.width,
                attrs: {
                  model: _vm.dynamicValidateForm,
                  "label-position": _vm.formObj.formproperties.labelalignment,
                  "label-width": _vm.formObj.formproperties.formlabelwidth
                }
              },
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  _vm._l(_vm.dynamicValidateForm.domains, function(
                    domain,
                    index
                  ) {
                    return _c(
                      "el-col",
                      { key: index, attrs: { span: Number(domain.span) } },
                      [
                        _c(
                          "el-form-item",
                          {
                            staticClass: "flex-1",
                            attrs: {
                              label: domain.hidelabels
                                ? _vm.$t(domain.label) + "："
                                : "",
                              prop: domain.check
                                ? "domains." + index + ".value"
                                : "",
                              rules: domain.check
                                ? {
                                    required: true,
                                    message: _vm.$t(domain.message),
                                    trigger: "blur"
                                  }
                                : {}
                            }
                          },
                          [
                            domain.category == 0
                              ? _c("el-input", {
                                  class: domain.classname,
                                  attrs: {
                                    type: domain.type,
                                    placeholder: _vm.$t(domain.placeholder),
                                    disabled: domain.disabled,
                                    clearable: ""
                                  },
                                  model: {
                                    value: domain.value,
                                    callback: function($$v) {
                                      _vm.$set(domain, "value", $$v)
                                    },
                                    expression: "domain.value"
                                  }
                                })
                              : _vm._e(),
                            domain.category == 1
                              ? _c(
                                  "el-select",
                                  {
                                    staticClass: "w-100",
                                    class: domain.classname,
                                    attrs: {
                                      clearable: "",
                                      placeholder: _vm.$t(domain.placeholder),
                                      disabled: domain.disabled,
                                      multiple: domain.multiplechoice
                                    },
                                    on: {
                                      change: function($event) {
                                        return _vm.selectChange(domain)
                                      }
                                    },
                                    model: {
                                      value: domain.value,
                                      callback: function($$v) {
                                        _vm.$set(domain, "value", $$v)
                                      },
                                      expression: "domain.value"
                                    }
                                  },
                                  _vm._l(domain.options, function(v, index) {
                                    return _c("el-option", {
                                      key: index,
                                      attrs: {
                                        disabled: v.disabled,
                                        label: _vm.$t(v.label),
                                        value: v.value
                                      }
                                    })
                                  }),
                                  1
                                )
                              : _vm._e(),
                            domain.category == 2
                              ? _c(
                                  "div",
                                  {
                                    class:
                                      domain.layoutmode == 0
                                        ? ""
                                        : domain.layoutmode == 1
                                        ? "d-flex flex-column"
                                        : ""
                                  },
                                  _vm._l(domain.options, function(v, index) {
                                    return _c(
                                      "el-radio",
                                      {
                                        key: index,
                                        class: domain.classname,
                                        staticStyle: {
                                          "margin-bottom": "15px"
                                        },
                                        attrs: {
                                          disabled: v.disabled,
                                          label: v.value
                                        },
                                        model: {
                                          value: domain.value,
                                          callback: function($$v) {
                                            _vm.$set(domain, "value", $$v)
                                          },
                                          expression: "domain.value"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t(v.label)) +
                                            "\n              "
                                        )
                                      ]
                                    )
                                  }),
                                  1
                                )
                              : _vm._e(),
                            domain.category == 3
                              ? _c(
                                  "div",
                                  {
                                    class:
                                      domain.layoutmode == 0
                                        ? ""
                                        : domain.layoutmode == 1
                                        ? "d-flex flex-column"
                                        : ""
                                  },
                                  [
                                    _c(
                                      "el-checkbox-group",
                                      {
                                        class: domain.classname,
                                        model: {
                                          value: domain.value,
                                          callback: function($$v) {
                                            _vm.$set(domain, "value", $$v)
                                          },
                                          expression: "domain.value"
                                        }
                                      },
                                      _vm._l(domain.options, function(
                                        v,
                                        index
                                      ) {
                                        return _c(
                                          "el-checkbox",
                                          {
                                            key: index,
                                            attrs: {
                                              label: v.value,
                                              disabled: v.disabled
                                            }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t(v.label)) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            domain.category == 4
                              ? _c("el-time-picker", {
                                  staticClass: "w-100",
                                  class: domain.classname,
                                  attrs: {
                                    disabled: domain.disabled,
                                    placeholder: _vm.$t(domain.placeholder)
                                  },
                                  model: {
                                    value: domain.value,
                                    callback: function($$v) {
                                      _vm.$set(domain, "value", $$v)
                                    },
                                    expression: "domain.value"
                                  }
                                })
                              : _vm._e(),
                            domain.category == 5
                              ? _c("el-date-picker", {
                                  attrs: {
                                    type: domain.type || "date",
                                    clearable: "",
                                    "range-separator": _vm.$t(
                                      "range-separator"
                                    ),
                                    "start-placeholder": _vm.$t(
                                      "start-placeholder"
                                    ),
                                    "end-placeholder": _vm.$t(
                                      "end-placeholder"
                                    ),
                                    placeholder: _vm.$t(domain.placeholder)
                                  },
                                  model: {
                                    value: domain.value,
                                    callback: function($$v) {
                                      _vm.$set(domain, "value", $$v)
                                    },
                                    expression: "domain.value"
                                  }
                                })
                              : _vm._e(),
                            domain.category == 6
                              ? _c("el-switch", {
                                  attrs: {
                                    "active-color":
                                      domain.activecolor || "#13ce66",
                                    "inactive-color":
                                      domain.inactivecolor || "#ff4949"
                                  },
                                  model: {
                                    value: domain.value,
                                    callback: function($$v) {
                                      _vm.$set(domain, "value", $$v)
                                    },
                                    expression: "domain.value"
                                  }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  1
                ),
                _vm.dynamicValidateForm.domains.length > 0
                  ? _c(
                      "el-form-item",
                      { staticClass: "text-right" },
                      [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.submitForm("dynamicValidateForm")
                              }
                            }
                          },
                          [
                            _vm._v(
                              _vm._s(_vm.$t(_vm.submitText || "submit")) +
                                "\n        "
                            )
                          ]
                        ),
                        _c(
                          "el-button",
                          {
                            on: {
                              click: function($event) {
                                return _vm.resetForm("dynamicValidateForm")
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("reset")))]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }