var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "materialsaddedit" },
    [
      _c("Form", {
        attrs: {
          data: _vm.formObj,
          submit: _vm.submitForm,
          reset: _vm.resetForm,
          Change: _vm.Change
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }