<template>
  <div class="Form" id="Form">
    <div style="padding: 15px" v-if="formObj">
      <div class="" style="margin-bottom: 20px;">
        <p style="font-size: 24px;font-weight: 500">{{ $t(formObj.title) }}</p>
      </div>
      <el-form :model="dynamicValidateForm" ref="dynamicValidateForm"
               :label-position="formObj.formproperties.labelalignment"
               :label-width="formObj.formproperties.formlabelwidth"
               class="demo-dynamic" :class="formObj.formproperties.classname"
               :style="'width: ' + formObj.formproperties.width">
        <el-row :gutter="24">
          <el-col v-for="(domain, index) in dynamicValidateForm.domains" :span="Number(domain.span)"
                  :key="index">
            <el-form-item class="flex-1"
                          :label="domain.hidelabels ? ($t(domain.label) + '：') : ''"
                          :prop="domain.check ? ('domains.' + index + '.value') : ''"
                          :rules="domain.check ? {required: true, message: $t(domain.message), trigger: 'blur'} : {}"
            >
              <el-input v-model="domain.value" :type="domain.type" :placeholder="$t(domain.placeholder)"
                        :class="domain.classname"
                        :disabled="domain.disabled" clearable v-if="domain.category == 0"></el-input>
              <el-select v-model="domain.value" clearable :placeholder="$t(domain.placeholder)" @change="selectChange(domain)"
                         :class="domain.classname"
                         :disabled="domain.disabled" :multiple="domain.multiplechoice" v-if="domain.category == 1"
                         class="w-100">
                <el-option
                    v-for="(v, index) in domain.options"
                    :key="index"
                    :disabled="v.disabled"
                    :label="$t(v.label)"
                    :value="v.value">
                </el-option>
              </el-select>
              <div v-if="domain.category == 2"
                   :class="domain.layoutmode == 0 ? '' : domain.layoutmode == 1 ? 'd-flex flex-column' : ''">
                <el-radio v-model="domain.value" style="margin-bottom: 15px;" :disabled="v.disabled"
                          :class="domain.classname"
                          v-for="(v, index) in domain.options" :label="v.value" :key="index">
                  {{ $t(v.label) }}
                </el-radio>
              </div>
              <div v-if="domain.category == 3"
                   :class="domain.layoutmode == 0 ? '' : domain.layoutmode == 1 ? 'd-flex flex-column' : ''">
                <el-checkbox-group v-model="domain.value" :class="domain.classname">
                  <el-checkbox v-for="(v, index) in domain.options" :label="v.value" :disabled="v.disabled"
                               :key="index">{{ $t(v.label) }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
              <el-time-picker class="w-100" :class="domain.classname" :disabled="domain.disabled"
                              v-model="domain.value" v-if="domain.category == 4"
                              :placeholder="$t(domain.placeholder)">
              </el-time-picker>
              <el-date-picker
                  v-model="domain.value" v-if="domain.category == 5"
                  :type="domain.type || 'date'" clearable
                  :range-separator="$t('range-separator')"
                  :start-placeholder="$t('start-placeholder')"
                  :end-placeholder="$t('end-placeholder')"
                  :placeholder="$t(domain.placeholder)">
              </el-date-picker>
              <el-switch v-if="domain.category == 6"
                         v-model="domain.value"
                         :active-color="domain.activecolor || '#13ce66'"
                         :inactive-color="domain.inactivecolor || '#ff4949'">
              </el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item class="text-right" v-if="dynamicValidateForm.domains.length > 0">
          <el-button type="primary" @click="submitForm('dynamicValidateForm')">{{ $t(submitText || 'submit') }}
          </el-button>
          <el-button @click="resetForm('dynamicValidateForm')">{{ $t('reset') }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>

export default {
  name: "Form",
  props: ['data', 'submit', 'reset', 'Change'],
  components: {},
  data() {
    return {
      formObj: {},
      dynamicValidateForm: {
        domains: []
      },
      submitText: '',
    }
  },
  created() {
    let formObj = this.data;
    this.dynamicValidateForm.domains = formObj.formData;
    this.formObj = formObj;
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // alert('submit!');
          this.submit(this.dynamicValidateForm.domains);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.reset('')
    },
    selectChange(item) {  /*change 事件自定义方法*/
      this.Change(item)
    }
  },
  watch:{
    formObj(New) {
      this.formObj = New;
      this.dynamicValidateForm.domains = New.formData;
    }
  }
}
</script>

<style scoped lang="scss">
.Form {
  width: 100%;
  height: 100%;
  background: #fff;
  border-radius: 15px;
  overflow: auto;
}
</style>

<style>
/deep/ .el-input__inner {
  height: 32px !important;
  border-radius: 4px !important;
  opacity: 0.49 !important;
  border: 1px solid #979797 !important;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100% !important;
}
</style>